<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("SETTINGS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
        </SearchTools>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingSetting"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['settings-update'])"
              :to="{ name: 'edit-setting', params: { id: item.id } }"
            >
              {{ item.name }}</router-link
            >
            <template v-else>{{ item.name }}</template>
          </template>
          <template v-slot:item.value="{ item }">
            <template>{{
              item.settingTypeSlug === "switch"
                ? item.value
                  ? $t("YES")
                  : $t("NO")
                : item.value
            }}</template>
          </template>
          <template v-slot:item.actions="{ item }">
            <ActionButton
              :to="{ name: 'edit-setting', params: { id: item.id } }"
              :permissions="['settings-update']"
              :label="$t('EDIT')"
              :icon="'mdi-pencil'"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";

import { mapGetters } from "vuex";

//Setting Module
import { FETCH_SETTINGS } from "@/modules/setting/store/setting.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 0,
      searchQuery: "",
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 50,
        totalItems: 0
      },
      rowsPerPageItems: [50, 40, 30, 20, 10],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Name", value: "name" },
        { text: "Slug", value: "slug" },
        { text: "Value", value: "value" },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: { SearchTools, ActionButton },
  watch: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: { name: "list-settings" } },
      { title: "Settings List" }
    ]);
    // this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getSettings", "isLoadingSetting"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery
      };
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      this.$store
        .dispatch(FETCH_SETTINGS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-settings" });
          }
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
